import { Carousel } from "@enzymefinance/swiper";
import { LoadingScreen } from "components/common/LoadingScreen";
import { VaultCard } from "components/discover/VaultCard";
import { EmptyState } from "components/feedback/EmptyState";
import { NavigateWithParams } from "components/routing/NavigateWithParams";
import { usePageTitle } from "utils/hooks/usePageTitle";

import { useWhiteLabel } from "../WhiteLabelProvider";

export function WhiteLabelHomeRoute() {
  const { description, error, loading, name, rootPath, vaults } = useWhiteLabel();
  usePageTitle(name);

  if (loading) {
    return <LoadingScreen />;
  }

  if (!vaults.length) {
    return <EmptyState title="No vaults on this page">This page does not have any vaults yet.</EmptyState>;
  }

  if (vaults.length === 1 && vaults[0]) {
    return <NavigateWithParams to={`${rootPath}vault/${vaults[0].address}`} />;
  }

  return (
    <section className="space-y-8 py-8 lg:space-y-16 lg:py-16">
      {description ? <p className="max-w-4xl font-medium sm:text-lg">{description}</p> : null}
      <Carousel appearance="default" title="Vaults">
        {vaults.map((vault) => (
          <Carousel.Item key={vault.address} width="lg">
            <VaultCard
              error={error ? "N/A" : undefined}
              key={vault.address}
              to={`${rootPath}vault/${vault.address}`}
              vault={vault}
              width="lg"
            />
          </Carousel.Item>
        ))}
      </Carousel>
    </section>
  );
}

export default WhiteLabelHomeRoute;
