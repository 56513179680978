import { ChevronLeftIcon, ChevronRightIcon } from "@enzymefinance/icons/solid";
import type { InlineLinkProps } from "@enzymefinance/ui";
import { Button, Card, InlineLink, SectionHeading, useMotionPresets } from "@enzymefinance/ui";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import type { ComponentPropsWithoutRef, Dispatch, ReactElement, ReactNode, SetStateAction } from "react";
import { createContext, useCallback, useContext, useState } from "react";
import type { Swiper as SwiperInstance } from "swiper";
import { Mousewheel } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

type CarouselAppearance = "card" | "default";

interface CarouselContextValues {
  appearance: CarouselAppearance;
  swiper?: SwiperInstance;
  setSwiper: Dispatch<
    SetStateAction<{
      instance?: SwiperInstance | undefined;
    }>
  >;
}

const CarouselContext = createContext<CarouselContextValues | undefined>(undefined);

export function useCarousel() {
  const context = useContext(CarouselContext);

  if (!context) {
    throw new Error("Missing carousel context");
  }

  return context;
}

export interface CarouselProps {
  actions?: ReactElement | null;
  appearance?: CarouselAppearance;
  error?: ReactElement | string;
  children: ReactNode;
  className?: string;
  title?: ReactElement | string;
}

export function Carousel({ actions, appearance = "card", children, className, error, title, ...props }: CarouselProps) {
  const [swiper, setSwiper] = useState<{ instance?: SwiperInstance }>({});
  const classes = classNames(
    { "md:px-6 md:pt-8 md:pb-10 lg:px-8 lg:pt-10 lg:pb-12": appearance === "card" },
    className,
  );
  const sectionHeadingClasses = classNames({ "-mx-4 sm:mx-0": appearance === "card" });
  const swiperWrapperClasses = classNames("grid relative", { "mx-4 sm:mx-0": appearance === "default" });

  const content = (
    <>
      <SectionHeading className={sectionHeadingClasses} actions={actions} wrap={false}>
        {title}
      </SectionHeading>
      {error ?? (
        <CarouselContext.Provider value={{ appearance, setSwiper, swiper: swiper.instance }}>
          <div className={swiperWrapperClasses}>
            <Swiper
              className="relative overflow-hidden"
              modules={[Mousewheel]}
              mousewheel={{ forceToAxis: true }}
              observer={true}
              onSlideChange={(instance) => setSwiper({ instance })}
              onSlideResetTransitionEnd={(instance) => setSwiper({ instance })}
              onSwiper={(instance) => setSwiper({ instance })}
              onTouchMove={(instance) => setSwiper({ instance })}
              slidesPerGroup={1}
              slidesPerView="auto"
              spaceBetween={16}
              speed={300}
              watchSlidesProgress={true}
              {...props}
            >
              {children}
            </Swiper>
            <Carousel.Gradients />
            <Carousel.Navigation />
          </div>
        </CarouselContext.Provider>
      )}
    </>
  );

  if (appearance === "card") {
    return (
      <Card full={true} rounded="2xl">
        <Card.Content className={classes}>{content}</Card.Content>
      </Card>
    );
  }

  return <section className={classes}>{content}</section>;
}

type CarouselItemAppearance = "primary" | "secondary";

export interface CarouselItemProps {
  appearance?: CarouselItemAppearance;
  children?: ReactNode;
  disabled?: boolean;
  height?: "auto" | "md" | "sm";
  width?: "lg" | "md" | "sm";
}

function carouselItem({
  disabled = false,
  height = "md",
  width = "sm",
  appearance = "secondary",
  ...props
}: CarouselItemProps) {
  const { appearance: carouselAppearance } = useCarousel();
  const classes = classNames("flex-none h-full relative transition-transform overflow-hidden", {
    "bg-gradient-to-r bg-gradient-primary rounded-lg text-white": appearance === "primary",
    "bg-base-300": appearance === "secondary" && carouselAppearance === "card",
    "bg-opacity-50": disabled,
    "bg-base-200": appearance === "secondary" && carouselAppearance === "default",
    "h-40": height === "sm",
    "h-60": height === "md",
    "h-auto": height === "auto",
    "rounded-2xl": carouselAppearance === "default",
    "rounded-lg": carouselAppearance === "card",
    "w-48": width === "sm",
    "w-72": width === "md",
    "w-[360px]": width === "lg",
  });

  return <SwiperSlide className={classes} {...props} />;
}

// This name is necessary since Swiper registers slides only when this component is named "SwiperSlide"
// Ref: https://github.com/nolimits4web/swiper/blob/master/src/react/get-children.js#L25-L27
carouselItem.displayName = "SwiperSlide";

Carousel.Item = carouselItem;

function carouselNavigation() {
  const motionPresets = useMotionPresets();
  const { swiper, setSwiper } = useCarousel();
  const handlePrevClick = useCallback(() => {
    swiper?.slidePrev();
    setSwiper({ instance: swiper });
  }, [setSwiper, swiper]);
  const handleNextClick = useCallback(() => {
    swiper?.slideNext();
    setSwiper({ instance: swiper });
  }, [setSwiper, swiper]);

  if (!swiper) {
    return null;
  }

  return (
    <div className="absolute inset-x-0 top-1/2 -translate-y-1/2">
      <AnimatePresence>
        {swiper.isBeginning ? null : (
          <motion.div className="absolute left-0 top-0" {...motionPresets.default} key="prev" onClick={handlePrevClick}>
            <Button
              className="-translate-y-1/2"
              circular={true}
              icon={ChevronLeftIcon}
              appearance="tertiary"
              onClick={handlePrevClick}
              size="xs"
            >
              Previous
            </Button>
          </motion.div>
        )}
        {swiper.isEnd ? null : (
          <motion.div
            className="absolute right-0 top-0"
            {...motionPresets.default}
            key="next"
            onClick={handleNextClick}
          >
            <Button
              className="-translate-y-1/2"
              circular={true}
              icon={ChevronRightIcon}
              appearance="tertiary"
              onClick={handleNextClick}
              size="xs"
            >
              Next
            </Button>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

Carousel.Navigation = carouselNavigation;

function carouselGradients() {
  const { appearance, swiper } = useCarousel();

  if (!swiper) {
    return null;
  }

  const commonClasses = classNames("absolute inset-y-0 w-16 lg:w-24 transition pointer-events-none", {
    "from-base-100 to-transparent": appearance === "default",
    "from-base-200 to-transparent": appearance === "card",
  });

  const leftClasses = classNames("left-0 bg-gradient-to-r", commonClasses, {
    "opacity-0 delay-200": swiper.isBeginning,
    "opacity-100": !swiper.isBeginning,
  });
  const rightClasses = classNames("right-0 bg-gradient-to-l", commonClasses, {
    "opacity-0 delay-200": swiper.isEnd,
    "opacity-100": !swiper.isEnd,
  });

  return (
    <>
      <div className={leftClasses} />
      <div className={rightClasses} />
    </>
  );
}

Carousel.Gradients = carouselGradients;

type CarouselLinkProps<TLinkProps extends ComponentPropsWithoutRef<"a"> = ComponentPropsWithoutRef<"a">> =
  InlineLinkProps<TLinkProps>;

function carouselLink<TLinkProps extends ComponentPropsWithoutRef<"a"> = ComponentPropsWithoutRef<"a">>(
  props: CarouselLinkProps<TLinkProps>,
) {
  return (
    <InlineLink
      className="group h-full font-semibold"
      trailingIcon={ChevronRightIcon}
      transitionOnHover={true}
      {...props}
    />
  );
}

Carousel.Link = carouselLink;
