import type { ReactNode } from "react";
import { createContext, useContext, useState } from "react";

const darkModeKey = "dark-mode";

function checkIsDarkModeOn() {
  return document.documentElement.classList.contains("dark");
}

function setUpDarkMode() {
  const darkMode = localStorage.getItem(darkModeKey);

  if (darkMode === null || darkMode === "true") {
    document.documentElement.classList.add("dark");
  }

  if (darkMode === "false") {
    document.documentElement.classList.remove("dark");
  }

  return checkIsDarkModeOn();
}

interface DarkLightModeContextValue {
  isDarkModeOn: boolean;
  switchDarkMode: () => void;
}

const DarkLightModeContext = createContext<DarkLightModeContextValue>({
  isDarkModeOn: true,
  switchDarkMode: () => {},
});

export function useDarkLightMode() {
  return useContext(DarkLightModeContext);
}

export function DarkLightModeProvider({
  children,
}: {
  children?: ReactNode;
}) {
  const [isDarkModeOn, setIsDarkModeOn] = useState(setUpDarkMode());

  function switchDarkMode() {
    document.documentElement.classList.toggle("dark");

    const darkMode = checkIsDarkModeOn();

    localStorage.setItem(darkModeKey, darkMode.toString());
    setIsDarkModeOn(darkMode);
  }

  return (
    <DarkLightModeContext.Provider
      value={{
        isDarkModeOn,
        switchDarkMode,
      }}
    >
      {children}
    </DarkLightModeContext.Provider>
  );
}
