import type { NavigateProps } from "react-router-dom";
import { Navigate, useLocation } from "react-router-dom";

interface NavigateWithParamsProps extends Omit<NavigateProps, "to"> {
  to: string;
}

/**
 * An enhanced version of <Navigate> that allows using matched params on the redirect route
 */
export function NavigateWithParams({ to, ...props }: NavigateWithParamsProps) {
  const location = useLocation();

  return <Navigate to={{ pathname: to, search: location.search }} {...props} />;
}
