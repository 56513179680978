import type { CurrencySlug, Deployment } from "@enzymefinance/environment";
import { Image } from "@enzymefinance/ui";
import { sizeAttributes } from "@enzymefinance/utils";
import classNames from "classnames";

type VaultSparklineWidth = 1 | 1.5 | 2 | 3;

interface VaultSparklineProps {
  className?: string;
  currency: CurrencySlug;
  id: string;
  deployment: Deployment;
  width?: VaultSparklineWidth;
  dashline?: boolean;
  darkMode: boolean;
}

export function VaultSparkline({
  className,
  id,
  currency,
  deployment,
  width = 3,
  dashline = false,
  darkMode,
  ...props
}: VaultSparklineProps) {
  const url = `/vault/${deployment}/${id}/sparkline?currency=${currency}&width=${width}&dashline=${dashline}&darkMode=${darkMode}`;

  const classes = classNames("object-contain", className);

  return <Image alt={currency} className={classes} src={url} {...sizeAttributes.full} {...props} />;
}
